import React from 'react';
import './FillsGap.css';

const FillsGap = () => {
  return (
    <section className="main-gap-section">
      <h1 className="gap-main-title">ATA fills the gaps in tech education</h1>
      <div className="gap-grid-layout">
        <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="1_PracticalExp.png"
              alt="Practical Experience"
            />
          </div>
          <div className="gap-item-content">
            <h3 className="gap-item-title">1. Practical Experience</h3>
            <p className="gap-item-description">
              Many programs focus on theory without showing students how to
              apply coding concepts and often hire instructors and professors
              who haven’t worked in the industry recently. We incorporate
              project-based learning where students build real-world projects
              and parts of existing apps, showing you how companies like
              Netflix, Amazon, and Tinder build their services.
            </p>
          </div>
        </div>

        <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="2_ModernCurriculum.png"
              alt="UpToDateCurriculum-img"
            />
          </div>
          <div className="gap-item-content">
            <h3 className="gap-item-title">2. Up-to-Date Curriculum</h3>
            <p className="gap-item-description">
              Tech evolves rapidly and hardly any tech curriculum covers modern
              tools such as AI which are used daily by engineers. ATA’s
              curriculum was built by asking employers what skills their best
              engineers have in 2024 and then adding then incorporating those
              skills into our curriculum. We incorporate AI, modern security
              tools, and constantly track changes and adapt accordingly.
            </p>
          </div>
        </div>

        <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="3_ConstantAccessSenior.png"
              alt="AccessToSeniorEng-img"
            />
          </div>
          <div className="gap-item-content">
            <h3 className="gap-item-title">
              3. Constant Access to Senior Engineers
            </h3>
            <p className="gap-item-description">
              Other schools give you access to instructors during class and
              office hours. At ATA, from the time you join, you’ll be in a chat
              room with multiple senior engineers where you can ask questions
              and receive quick responses.
            </p>
          </div>
        </div>

        <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="4_InterviewPrep.png"
              alt="InterviewPrep-img"
            />
          </div>
          <div className="gap-tem-content">
            <h3 className="gap-item-title">4. Interview Prep</h3>
            <p className="gap-item-description">
              We stay up to date with current tech questions and best practices
              for interviewing and make sure you have the skills to be
              successful. Additionally, we provide reviews of your resume and
              LinkedIn.
            </p>
          </div>
        </div>

        {/* <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="5_JobGuarantee.png"
              alt="JobGuarantee-img"
            />
          </div>
          <div className="gap-item-content">
            <h3 className="gap-item-title">5. Job Guarantee</h3>
            <p className="gap-item-description">
              The curriculum we created has been tried and tested at other
              bootcamps, so we know it works. We are so confident that this
              works that we offer a 50% money-back guarantee for any students
              who don’t get a high paying job within seven months of graduating
              our expert-level courses.
            </p>
          </div>
        </div> */}

        <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="6_IndustryCertification.png"
              alt="certification-img"
            />
          </div>
          <div className="gap-item-content">
            <h3 className="gap-item-title"> 5. Industry Certification</h3>
            <p className="gap-item-description">
              Code schools usually give you certification in a specific language
              or tool. Our certification will show employers that you are not
              only proficient in a programming language, you also have a deep
              knowledge of cloud computing, and databases, and tools for
              building enterprise-level applications.
            </p>
          </div>
        </div>

        <div className="gap-grid-item">
          <div className="gap-image-container">
            <img
              className="item-image"
              src="7_FreeBooks.png"
              alt="textbook-img"
            />
          </div>
          <div className="gap-item-content">
            <h3 className="gap-item-title">6. Free Textbooks</h3>
            <p className="gap-item-description">
              Prices for textbooks are so high these days, we’re lucky if we
              don’t have to sell a kidney. At ATA we believe learning should be
              accessible to everyone. We purchase e-books in bulk and provide
              students a soft copy for free.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FillsGap;
